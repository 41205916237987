import React,{useContext} from 'react'
import ExtruderCard from '../Cards/ExtruderCard'
import DataContext from '../../Contexts/DataContext'
export default function ExtruderAccordion({ extruder,costs }) {
    const {text} = useContext(DataContext)
    return (
        <div>
            <div className="row extruder-accordion">
                <div className="col-md-12 accordion">
                    <div className="card"  data-toggle="collapse" data-target={`#extruder${extruder.no}`} aria-expanded="true" aria-controls={`#extruder${extruder.no}`}>
                        <button className="accordion-button" type="button">
                            {text("Extruder")} {`${String.fromCharCode(65 + extruder.no)}`}
                        </button>
                    </div>
                </div>
            </div>
            <ExtruderCard extruder={extruder} costs={costs} />
        </div>
    )
}
