import React, { useRef,useContext } from 'react'
import axios from 'axios';
import DataContext from '../../Contexts/DataContext';

export default function EditShiftForm(shift) {
  const {text} = useContext(DataContext)
  const formRef = useRef();
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const apiUrl = 'http://172.16.55.55:5001/Authorize/Auth';
    const username = 'metroplastik';
    const password = 'metro123';

    axios.get(apiUrl, {
      params: Object.fromEntries(new FormData(formRef.current).entries())
    })
      .then(response => {
        // handle the response data
      })
      .catch(error => {
        // handle the error
      });
  };

  return (
    <form onSubmit={handleSubmit} datatype="text" className="shift-form" ref={formRef}>
            <div className="form-group">
                <label >{text("Shift_Name")}</label>
                <input type="text" id="name" name='UserName' defaultValue={shift.name} />
            </div>
            <div className="form-group">
                <label >{text("Start")}</label>
                <input type="time" id='startTime' name='Password' defaultValue={shift.startTime} />
            </div>
            <div className="form-group">
                <label >{text("Stop")}</label>
                <input type="time" id="endTime" defaultValue={shift.endTime} />
            </div>
            <button className="btn btn-sm btn-info f" type="submit">{text("Save")}</button>
        </form>
  )
}
