import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWifi, faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import DataContext from "../../../Contexts/DataContext";
export default function Machine({ machine, changeEvent,changeName }) {
  const { text } = useContext(DataContext);

  return (
    <tr className="trbody">

      <td data-label={text("State")} >
        <FontAwesomeIcon icon={faWifi} color={machine.isConnected ? "#1abc9c" : "#f1556c"} />
      </td>

      <td data-label={text("Name")} >
        <Link  to={`/machineDetail/${machine.id}`}>{machine.name}</Link>
      </td>

      <td data-label={text("Actual_Capacity")} className="text-capacity">
        {machine.isConnected?machine.machineData.activeCapacity.toFixed(2):0} {text("kg/h")}
      </td>

      <td data-label={text("Target_Capacity")} className="text-target-capacity">
        {machine.isConnected?machine.machineData.targetCapacity.toFixed(2):0} {text("kg/h")}
      </td>

      <td data-label={text("Edit")} className="text-edit  text-center">
        <Link className="btn btn-sm btn-success mr-10" to={`machineDetail/${machine.id}`}>
          <FontAwesomeIcon icon={faEye} />
        </Link>

        <button className="btn btn-sm btn-warning" data-target="#modalChangeMachine" onClick={() => {
          changeEvent(machine.id);changeName(machine.name)
        }} data-toggle="modal">
          <FontAwesomeIcon icon={faEdit} />
        </button>
      </td>
    </tr>
  );
}
