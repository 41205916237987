import React from 'react'

export default function ReportHeadCard({title,value}) {
    return (
        <div className="card data-card">
            <div className="data-title">{title}</div>
            <div className="data-value">
                {value}
            </div>
            <div className="data-sets justify-content-between d-flex">
                <span>{"-"}</span>
            </div>
        </div>
    )
}
