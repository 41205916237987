import React, { useContext } from "react";
import MachineTable from "../Components/Tables/MachineTable";
import FactoryCapacityCard from "../Components/Cards/FactoryCapacityCard";
import ApiContext from "../Contexts/ApiContext";
import PreLoader from "../Components/SeedWorks/PreLoader";
import E401 from "../Components/SeedWorks/Errors/E401";
import E404 from "../Components/SeedWorks/Errors/E404";
import E500 from "../Components/SeedWorks/Errors/E500";
export default function Dahsboard({ title }) {
  const { machines } = useContext(ApiContext);
  if (machines === null) {
    return <PreLoader />;
  } else if (machines.response.code !== 200) {
    switch (machines.response.code) {
      case 401:
        return <E401 />;
      case 404:
        return <E404 />;
      case 501:
        return <E500 />;
      default:
        return <E500 />;
    }
  } else {
    
    const machinesData = machines?.response?.data;
    return (
      <div className="dahsboard">
        <div className="row content-header">
          <div className="col-12">
            <div className="page-title">
              <h5> {title} </h5>
            </div>
          </div>
        </div>
        <div className="row">
          {/* Grafik Alani */}
          <div className="col-md-4">
            <FactoryCapacityCard machines={machinesData} />
          </div>
          {/* Tablo Alani */}
          <div className="col-md-8">
            <MachineTable machines={machinesData} />
          </div>
        </div>
      </div>
    );
  }
}
