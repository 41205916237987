import React, { useContext } from 'react'
import BarChart from '../Charts/BarChart'
import DataContext from '../../Contexts/DataContext'
export default function RawConsuptionCard({ rawMaterials }) {
    const { text } = useContext(DataContext)
    return (
        <div className="card">
            <div className="card-title">{text("Consumption")}</div>
            <div className="test">
                <BarChart datas={rawMaterials.map((raw) => {

                    return ({
                        x: raw.name,
                        y: raw.totalConsumption.toFixed(2)
                    })
                })} />
            </div>
        </div>
    )
}
