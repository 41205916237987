import React, {useContext} from 'react'
import {faArrowDown,faEquals} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataContext from '../../Contexts/DataContext';
export default function MachineDataCard({ title, target, active, unit }) {
    const {text}= useContext(DataContext)
    const percent = (target, active) => {
        var percent = Math.min((active / target * 100),100);
        var icon = percent != 100 ? faArrowDown : faEquals
        var color = percent == 100 ? "success" : "danger";
        return <span className={color}> <FontAwesomeIcon icon={icon} /> {(percent===NaN?0:percent).toFixed(0)}%</span>
    }
    return (
        <div className="card data-card">
            <div className="data-title"> {title}</div>
            <div className="data-value">
                {active.toFixed(2)}
                <span> {unit}</span>
            </div>
            <div className="data-sets justify-content-between d-flex">
                <span><span className='text-bold'>{text("Target")}</span>: {target.toFixed(2)} {unit}</span>
                {percent(target, active)}
            </div>
        </div>
    )
}
