import React, { useContext, useState, useEffect } from "react";
import OrderTable from "../../Components/Tables/OrderTable";
import { ParsApi } from "../../Components/Api/ParsApi";
import PreLoader from "../../Components/SeedWorks/PreLoader";
import E401 from "../../Components/SeedWorks/Errors/E401";
import E404 from "../../Components/SeedWorks/Errors/E404";
import E500 from "../../Components/SeedWorks/Errors/E500";
function ProductReports({ title }) {
  const { parsApi } = ParsApi();
  const [machines, setMachines] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      parsApi.getOrders(setMachines);
    };
    fetchData();
  }, []);

  if (machines == null) {
    return <PreLoader />;
  } else if(machines.response.code !== 200){
    switch (machines.response.code) {
      case 401:
        return <E401 />;
      case 404:
        return <E404 />;
      case 501:
        return <E500 />;
      default:
        return <E500 />;
    }
  }else {
    const machinesData = machines.response.data

    return (
      
      <div className="product-reports">
        <div className="row content-header">
          <div className="col-12">
            <div className="page-title">
              <h5>{title}</h5>
            </div>
          </div>
        </div>

        <div className="row">
          {machinesData.map((machine) => {
            return (
              <div key={machine.id} className="col-md-6 col-lg-4">
                <OrderTable machine={machine} orders={machine.orders} />
              </div>
            );
          })}
        </div>
      </div>
    );

  }
    
}

export default ProductReports;
