import React, { useContext } from "react";
import ApiContext from "../Contexts/ApiContext";
import MachineCard from "../Components/Cards/MachineCard";
import E401 from "../Components/SeedWorks/Errors/E401";
import E404 from "../Components/SeedWorks/Errors/E404";
import E500 from "../Components/SeedWorks/Errors/E500";
import PreLoader from "../Components/SeedWorks/PreLoader";
export default function Livedata({ title }) {
  const { machines } = useContext(ApiContext);
  if (machines === null) {
    return <PreLoader />;
  } else if (machines.response.code !== 200) {
    switch (machines.response.code) {
      case 401:
        return <E401 />;
      case 404:
        return <E404 />;
      case 501:
        return <E500 />;
      default:
        return <E500 />;
    }
  } else {
    const machinesData = machines.response.data;
    return (
      <div className="livedata">
        <div className="row content-header">
          <div className="col-12">
            <div className="page-title">
              <h5>{title}</h5>
            </div>
          </div>
        </div>
        <div className="row">
        {machinesData?.map((machine) => {
            return (
              <div key={machine.id} className="col-md-6 col-xl-4">
                <MachineCard machine={machine} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
