import React from "react";
import ReactApexChart from "react-apexcharts";
export default function LineChart({ data }) {

  const series= [
    {
      name: "Series A",
      data:   data?.map((dt)=>{return dt?.yAxis?.toFixed(2)})}];

  const options = {

    chart: {
      type: "line",
      zoom: {
        enabled: 0
      },
      toolbar: {
        show: !0
      }
    },
    plotOptions: {
        bar: {
            dataLabels: {
                position: 'top',

            }
        },
    },
    dataLabels: {
      enabled: false,
  },
 
    yaxis: {
      max:()=>{return (Math?.max(...series[0]?.data)+10)},
      min:()=>{return (Math?.min(...series[0]?.data)-10)}
  }  ,
    xaxis: {
      categories: data?.map((dt)=>  {
          return  (`${dt?.xAxis?.substring(0,10)} ${dt?.xAxis?.substring(11,16)}`)?.replaceAll('-','.')
        }),
      type:'datetime',
      labels: {
        datetimeFormatter: {
          format:'yyyy-MM-dd HH:mm'
        }
      }
    },
    colors: ["rgb(26,188,156)"],
    stroke: {
      width: [5, 4, 5, 4, 5, 4, 5, 4, 5, 4, 5, 4, 5,],
      curve: "smooth"
    },
    markers: {
      size: 0,
      colors: ["@6f6f6f", "@6f6f6f", "@6f6f6f", "@6f6f6f", "@6f6f6f", "@6f6f6f", "@6f6f6f"],
      strokeColors: '#fff',
      strokeWidth: 0,
      hover: {
        size: 4,
        sizeOffset: 0
      }
    },
    title: {
      text: "",
      align: "left",
      style: {
        fontSize: "14px",
        color: "#900"
      }
    }
  };
  return (
    <div id="chart">
      <ReactApexChart options={options} id={"test"} series={series} height={250} />
    </div>
  );
}
