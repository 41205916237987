import React, { useContext } from "react";
import Header from "./Components/SeedWorks/Header";
import Sidebar from "./Components/SeedWorks/Sidebar";
import Footer from "./Components/SeedWorks/Footer";
import "./App.css";
import "./Print.css";
import Container from "./Components/SeedWorks/Container";
import MobileNavigation from "./Components/SeedWorks/MobileNavigation";
import DataContext from "./Contexts/DataContext";
import { ApiContextProvider } from "./Contexts/ApiContext";
import { BrowserRouter } from "react-router-dom";
import { useCookies } from "react-cookie";
function App() {
  const { settings } = useContext(DataContext);
  let timeout;
const [,removeCookie]=useCookies('session')
const startTimer = () => {
  timeout = setTimeout(() => { 
    removeCookie('session');
    window.location.reload();
  }, 15 * 60 * 1000); 
};

const resetTimer = () => {
  clearTimeout(timeout);
  startTimer();
};

const handleMouseMove = () => {
  resetTimer();
};

document.addEventListener('mousemove', handleMouseMove);
startTimer();
  return (
    <BrowserRouter>
        <ApiContextProvider>
          <div id="wrapper" className={`wrapper ${settings.sidebarToggle ? "collapsed" : ""}`}>
            <Sidebar />
            <div className="content-area">
              <Header />
              <Container />
              <Footer />
            </div>
            <MobileNavigation />
          </div>
        </ApiContextProvider>
    </BrowserRouter>
  );
}

export default App;
