import React, { useContext } from "react";
import DonutChart from "../Charts/DonutChart";
import RawMaterialTable from "../Tables/RawMaterialTable";
import MachineDataCard from "./MachineDataCard";
import DataContext from "../../Contexts/DataContext";
import CostCard from "./CostCard";

export default function ExtruderCard({ extruder,costs }) {
  const { text } = useContext(DataContext);

  return (
    <div id={`extruder${extruder.no}`} className="row collapse ">

      <div className="col-sm-4">
        <MachineDataCard title={text("Capacity")} target={extruder.targetCapacity} active={extruder.activeCapacity} unit={text("kg/h")} />
      </div>
      <div className="col-sm-4">
        <MachineDataCard title={text("Thickness")} target={extruder.targetThickness} active={extruder.activeThickness} unit={text("µ")} />
      </div>
      <div className="col-sm-4">
        <CostCard costs={costs} extruderNo={extruder.no} />
      </div>
      <div className="col-sm-4">
        <div className="card">
          <div className="card-title">{text("Raw_Material_Ratios")}</div>
          <DonutChart series={extruder.rawMaterialData.map((rawMaterial => rawMaterial.ratio))} labels={extruder.rawMaterialData.map((rawMaterial => rawMaterial.name))} />
        </div>
      </div>

      <div className="col-sm-8">
        <RawMaterialTable rawMaterials={extruder.rawMaterialData} />
      </div>
    </div>
  );
}
