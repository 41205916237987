import React, { useContext } from "react";
import RecipeRaw from "./Rows/RecipeRaw";
import DataContext from "../../Contexts/DataContext";
import uuid from 'react-uuid';
import * as XLSX from "xlsx";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function RecipeTable({ rawMaterials, kgCosts }) {
  const { text } = useContext(DataContext);
  const exportToExcel = () =>{
    const worksheet = XLSX.utils.json_to_sheet(rawMaterials);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook,worksheet,"Sheet1");
    XLSX.writeFile(workbook,"Recipe.xlsx") 
  }
  let totalConsup = 0 ;
  for (let index = 0; index < rawMaterials.length; index++) {
    totalConsup += parseFloat(rawMaterials[index].totalConsumption.toFixed(2)); // Her adımda totalConsup'a totalConsumption değerini ekliyoruz
  }


  return (
    <div className="card">
      <div className="flex flex-between " >
        <div className="card-title">{text("Recipe_Information")}</div>
        <button className="export-button" >
          <FontAwesomeIcon className="export-button-icon" onClick={exportToExcel} style={{fontSize:"18px"}} icon={faFileExcel} />
        </button>
      </div>
      <table className="responsive-table recipe">
        <thead>
          <tr>
            <th>{text("Raw_Material_Name")}</th>
            <th className="ratio">{text("Rate")}</th>
            <th>{text("Total_Consumption")}</th>
            <th>{text("Cost")} ({text("kg")})</th>
            <th>{text("Consumption_Cost")}</th>
          </tr>
        </thead>
        <tbody>
          {rawMaterials?.map((raw,index) => {
            return (<RecipeRaw key={uuid()} rawMaterial={raw} index={index +1} />)
            
          })}
          {
            kgCosts != null ? kgCosts.map((cost) => {
              return (
                <tr key={uuid()} className="kgCosts">
                  <td></td>
                  <td className="ratio"></td>
                  <td></td>
                  <td className="text-right kg-cost ">{text("Kg_Cost")}({cost.currency}) :</td>
                  <td  data-label={text("Kg_Cost")} >{cost.price?.toFixed(2)} {cost.currency}</td>
                </tr>
              )
            }) : ""
          }
          <tr className="kgCosts">
            <td></td>
            <td className="ratio"></td>
            <td></td>
            <td className="text-right kg-cost">{text("Total_Consumption")} :</td>
            <td data-label={text("Total_Consumption")}> {totalConsup.toFixed(2)} {text("kg/h")}</td>
          </tr>
          
        </tbody>
      </table>
    </div>
  );
}
