import React,{useContext} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPrint} from '@fortawesome/free-solid-svg-icons'
import DataContext from '../../Contexts/DataContext'
export default function PrintButton() {
  const {text} = useContext(DataContext)
  const Print = ()=>{
    var collapses = window.$('.collapse');
    for (let index = 0; index < collapses.length; index++) {
      collapses[index].classList.remove("collapse");
    }
    setTimeout(()=>{
      window.print();
    },500)
    
  }
  return (
    <button onClick={Print} className='btn-print'>
        <FontAwesomeIcon icon={faPrint}/>
        <span>{text("Print")}</span>
    </button>
  )
}
