import React, { useContext } from "react";
import DataContext from "../../Contexts/DataContext";
import Order from "./Rows/Order";
import uuid from "react-uuid"

export default function OrderTable({machine,orders}) {
  const { text } = useContext(DataContext);

  return (
    <div className="card">
      <div className="card-title">{machine.name}</div>
      <table className="responsive-table">
        <thead>
          <tr>
            <th>{text("Order_No")}</th>
            <th>{text("Statu")}</th>
            <th>{text("Start_Time")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            orders.map((order)=><Order key={order.no} order={order} />)
          }
        </tbody>
      </table>
    </div>
  );
}
