import React,{useContext} from 'react'
import { Link } from "react-router-dom"
import DataContext from '../../../Contexts/DataContext'
export default function E404() {
  const {text }= useContext(DataContext)
  return (
    <div className="response-error ">

      <h1>404</h1>
      <h5>{text("404P")}</h5>
      <Link to={"/"} >{ text('ErrorPageGoTo')}</Link>
    
  </div>
  )
}
