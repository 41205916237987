import React,{useContext} from 'react'
import DonutChart from '../Charts/DonutChart'
import DataContext from '../../Contexts/DataContext'
export default function ExtruderRatioCard({extrudersRatio,extrudersName}) {
    const {text}= useContext(DataContext)
    return (
        <div className="card">
            <div className="card-title">{text("Extruder_Ratios")}</div>
            <DonutChart series={extrudersRatio} labels={extrudersName}  />
        </div>
    )
}
