import React from 'react'
import LineChart from '../Charts/LineChart'

export default function MachineReportLineGraphCard({title,series}) {
  return (
   <div className="card">
    <div className="card-title">{title}</div>
    <LineChart data={series} title={title}/>
   </div>
  )
}
