import React, { useContext, useState, useEffect } from 'react'
import DataContext from '../Contexts/DataContext';
import { ParsApi } from '../Components/Api/ParsApi';
import { useParams, useLocation } from 'react-router-dom';
import ReportHeadCard from '../Components/Cards/ReportHeadCard';
import RecipeTable from '../Components/Tables/RecipeTable';
import MachineReportLineGraphCard from '../Components/Cards/MachineReportLineGraphCard';
import RawConsuptionCard from '../Components/Cards/RawConsuptionCard';
import PreLoader from '../Components/SeedWorks/PreLoader';
import PrintButton from '../Components/SeedWorks/PrintButton';
import E401 from '../Components/SeedWorks/Errors/E401';
import E404 from '../Components/SeedWorks/Errors/E404';
import E500 from '../Components/SeedWorks/Errors/E500';
export default function ProductReport() {
    const { text,dateFormat } = useContext(DataContext);
    const { parsApi } = ParsApi();
    const { id } = useParams();

    const location = useLocation();
    const orderId = new URLSearchParams(location.search).get('orderId');

    const [order, setOrder] = useState(null);

    useEffect(() => {
        const fetchData = async () => {

            parsApi.getOrder(id, orderId,setOrder)
        };
        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    if (order === null) {
        return (<PreLoader />)
    }else if (order.response.code !== 200) {
        switch (order.response.code) {
          case 401:
            return <E401 />;
          case 404:
            return <E404 />;
          case 501:
            return <E500 />;
          default:
            return <E500 />;
        }
      }
    else{
        const orderData = order.response.data
        return (
            <div className="machineReport">
                <div className="row content-header">
                    <div className="col-12">
                        <div className="page-title">
                            <h5>{text("Order_Detail")}</h5>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-3 col-md-6 col-lg-3" >
                        <ReportHeadCard title={text("Order_No")} value={orderData.no} />
                    </div>
                    <div className="col-sm-3 col-md-6 col-lg-3" >
                        <ReportHeadCard title={text("Machine_Name")} value={orderData.data.machineInfo.name} />
                    </div>
                    <div className="col-sm-3 col-md-6 col-lg-3" >
                        <ReportHeadCard title={text("Start_Time")} value={dateFormat(orderData.data.startTime)} />
                    </div>
                    <div className="col-sm-3 col-md-6 col-lg-3" >
                        <ReportHeadCard title={text("Stop_Time")} value={dateFormat(orderData.data.endTime)} />
                    </div>
                </div>
                <div className="row ">
                    <div className="col-sm-7">
                        <RecipeTable rawMaterials={orderData.data.recipe} kgCosts={orderData.data.totalCosts} />
                    </div>
                    <div className="col-sm-5">
                        <RawConsuptionCard rawMaterials={orderData.data.recipe} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <MachineReportLineGraphCard title={text("Capacity_Graph")} series={orderData.data.activeCapacitySeries} />
                    </div>
                    <div className="col-sm-6">
                        <MachineReportLineGraphCard title={text("Weight_Chart")} series={orderData.data.activeGrammageSeries} />
                    </div>
                    <div className="col-sm-6">
                        <MachineReportLineGraphCard title={text("Thickness_Chart")} series={orderData.data.activeThicknessSeries} />
                    </div>
                    <div className="col-sm-6">
                        <MachineReportLineGraphCard title={text("Line_Speed_Chart")} series={orderData.data.activeLineSpeedSeries} />
                    </div>
                </div>
                <PrintButton/>
            </div>
        )

    }
       
}
