import React from "react";
import ParsLogo from "../../Images/pars-logo.png"

export default function PreLoader() {
  return (
    <div id="loader-wrapper">
      <div id="loader" />
  
      <img className="logo-loader" src={ParsLogo} alt="" />
     
    </div>
  );
}
