import React,{useContext} from "react";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataContext from "../../Contexts/DataContext";
export default function Modal({ id, title, content, event, footerVisible }) {
    const {text} = useContext(DataContext)
    const modalContent = content();
    return (
        <div className="modal fade" id={id} tabIndex="-1" aria-labelledby={id} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">

                    <div className="modal-header">
                        <h5 className="modal-title">
                            {title}
                        </h5>
                        <button type="button" className="btn btn-sm btn-danger" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><FontAwesomeIcon icon={faXmark}/></span>
                        </button>
                    </div>

                    <div className="modal-body">
                        {modalContent}
                    </div>
                    
                    {
                        footerVisible ? <div className="modal-footer">
                            <a type="submit" className="btn btn-success" onClick={event}>
                                {text("Confirm")}
                            </a>
                        </div> : ""
                    }
                    
                </div>
            </div>
        </div>
    );
}
