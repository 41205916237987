import React, { useContext, useState } from "react";
import {faCheck,} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataContext from "../../Contexts/DataContext";
import Modal from "../SeedWorks/Modal";
import EditShiftForm from "../Forms/EditShiftForm";
import Shift from "./Rows/Shift";
export default function ShiftTable() {
  const { text } = useContext(DataContext);
  const [shiftId, setShiftId] = useState(-1);
  const [shift, setShift] = useState({
    id: 0,
    name: "",
    startTime: "",
    endTime: "",
  });
  const [shifts] = useState([
    {
      id: 0,
      name: "Vardiya 1",
      startTime: "07:00",
      endTime: "15:00",
    },

    {
      id: 2,
      name: "Vardiya 2 ",
      startTime: "15:00",
      endTime: "23:00",
    },
    {
      id: 3,
      name: "Vardiya 3 ",
      startTime: "23:00",
      endTime: "07:00",
    },
  ]);

  function deleteShift() {
    alert(shiftId);
  }

  return (
    <div className="card shift-from">
      <div className="card-title">{text("Shifts")}</div>
      <form action="">
        <table className="responsive-table">
          <thead>
            <tr>
              <th>{text("Shift_Name")}</th>
              <th>{text("Start")}</th>
              <th>{text("Stop")}</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {shifts.map((shift) => {
              return (
                <Shift
                  key={shift.id}
                  shift={shift}
                  changeEvent={setShift}
                  deleteEvent={setShiftId}
                />
              );
            })}

            <tr>
              <td data-label={text("Shift_Name")} className="form-group">
                <input type="text" />
              </td>
              <td data-label={text("Start")} className="form-group">
                <input  type="time" />
              </td>
              <td data-label={text("Stop")} className="form-group">
                <input  className="date-time-input" type="time" />
              </td>
              <td className="form-group">
                <button
                  type="submit"
                  className="btn btn-sm btn-info"
                  text="Ekle"
                >
                  <FontAwesomeIcon icon={faCheck} />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>

      <Modal
        id="deleteModal"
        title="Vardiya Sil"
        content={() => (
          <div>
            <h5>{text("Are_You_Sure_You_Want_To_Delete_It")}</h5>
          </div>
        )}
        event={deleteShift}
        footerVisible={true}
      />

      <Modal
        id="changeModal"
        title="Test"
        content={() => EditShiftForm(shift)}
        event={deleteShift}
        footerVisible={false}
      />
    </div>
  );
}
