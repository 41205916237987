import React, { useContext, useRef } from 'react'
import DataContext from '../../Contexts/DataContext'
import { ParsApi } from "../Api/ParsApi";

export default function EditMachineNameForm(machineId,machineName) {
  const { parsApi } = ParsApi();
  const { text } = useContext(DataContext);
  const formRef = useRef();

  const ChangeMachine = (e) => {
    e.preventDefault();
    var formdata = Object.fromEntries(new FormData(formRef.current).entries());
    formdata.Id = parseInt(machineId);
    parsApi.changeMachineName(formdata).then((response) => {
      if (response.status == 200) {
        window.location.reload(false);
      }
    });

  }

  return (
    <form action="" ref={formRef} className='machineNameChangeForm'>

      <div className="form-group d-none">
        <input type="text" name='Id' defaultValue={machineId} />
      </div>
      <div className="form-group">
        <label htmlFor="name">{text("Machine_Name")}</label>
        <input defaultValue={machineName} type="text" name='Name' placeholder={text("Machine_Name")} />
      </div>
      <button className='btn btn-sm btn-success' onClick={ChangeMachine}>
        {text("Change")}
      </button>
    </form>


  )
}
