import React,{useContext} from 'react'
import DataContext from '../../Contexts/DataContext'
export default function OperationTimeCard({hour, minute,lastDate}) {
    const {text,dateFormat} = useContext(DataContext)
    return (
        <div id='operation-time-card' className="card data-card">
            <div className="data-title">{text("Runtime")}</div>
            <div className="data-value">
                <span> {hour}</span> {text("Hour")}
                <span> {minute}</span> {text("Minute")}
            </div>
            <div className="data-sets text-right">
                <span>{dateFormat(lastDate)}</span>
            </div>
        </div>
    )
}
