import React, { useContext } from "react";
import { Link } from "react-router-dom";
import CapacityChart from "../../Components/Charts/CapacityChart";
import DataContext from "../../Contexts/DataContext";

export default function MachineCard({ machine }) {
  const { text } = useContext(DataContext);
  const capacityPercent = Math.min(machine.machineData.activeCapacity / machine.machineData.targetCapacity * 100);
  const lineSpeedPercent = Math.min(machine.machineData.activeLineSpeed=== 0 ? 0 :machine.machineData.activeLineSpeed / machine.machineData.targetLineSpeed * 100, 100);
console.log( )
  return (
    <div className="card">
      <div className="card-title mb-0">{machine.name}</div>
      {machine.isConnected ? '' : (
        <div className={`machine-disconnected`}>
          <h1>{text("Disconnected")}</h1>
        </div>
      )}
      <div className="Worder-lg-2 chart-area">
        <div className="text-center">
          <Link to={`/machineDetail/${machine.id}`}>
            {text("Detailed_View")}
          </Link>
        </div>

        <div className="chart">
          {<CapacityChart text={text("Capacity")} value={(capacityPercent === NaN ? 0 : capacityPercent).toFixed(0)} name={"capacity" + machine.id} color={"#ffc80e"} />}
        </div>

        <div className="chart">
          {<CapacityChart text={text("Line_Speed")} value={(lineSpeedPercent === NaN ? 0 : lineSpeedPercent).toFixed(0)} name={"lineSpeed" + machine.id} color={"#1abc9c"} />}
        </div>
      </div>

      <div className="detail-area">
        <table>
          <tbody>
            <tr>
              <td className="title">{text("Capacity")}:</td>
              <td>{machine.machineData.activeCapacity.toFixed(2)} {text("kg/h")}</td>
            </tr>
            <tr>
              <td className="title">{text("Product_Weight")}:</td>
              <td>{machine.machineData.activeGrammage.toFixed(2)} {text("gr/m")}</td>
            </tr>
            <tr>
              <td className="title">{text("Thickness")}:</td>
              <td>{machine.machineData.activeThickness.toFixed(2)} {text("µ")} </td>
            </tr>
            <tr>
              <td className="title">{text("Line_Speed")}:</td>
              <td>{machine.machineData.activeLineSpeed.toFixed(2)} {text("m/min")}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
