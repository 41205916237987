import React, { useContext, useState } from "react";
import DataContext from "../../Contexts/DataContext";

export default function Language() {
  const { changeLanguage } = useContext(DataContext);
  const languages = (e) => {
    changeLanguage(e.target.getAttribute("name"));
  };

  let language = window.localStorage.getItem("i18nextLng");
  let flag;

  if (language === "tr-TR") {
    flag = "/flags/tr-TR.jpg";
  } else if (language === "en-US") {
    flag = "/flags/en-US.jpg";
  } else if (language === "ru-RU") {
    flag = "/flags/ru-RU.jpg";
  } else if (language === "cs-CS") {
    flag = "/flags/cs-CS.jpg";
  } else {
    flag = "/flags/tr-TR.jpg";
  }
  return (
    <div className="dropdown dropdown-lang">
      <a
        href="#" 
        className="d-block text-decoration-none dropdown-toggle lang-selection"
        role="button"
        data-toggle="dropdown"
        aria-expanded="false"
      >
        <img src={flag} height="16" />
      </a>
      <div onClick={languages} className="dropdown-menu">
        <a name="tr-TR" className="dropdown-item" href="#">
          <img name="tr-TR" src="/flags/tr-TR.jpg" width="30" height="20" />
          <span name="tr-TR">Turkce</span>
        </a>
        <a name="en-US" className="dropdown-item" href="#">
          <img name="en-US" src="/flags/en-US.jpg" width="30" height="20" />
          <span name="en-US">English</span>
        </a>
        <a name="ru-RU" className="dropdown-item" href="#">
          <img name="ru-RU" src="/flags/ru-RU.jpg" width="30" height="20" />
          <span name="ru-RU">Russian</span>
        </a>
        <a name="cs-CS" className="dropdown-item" href="#">
          <img name="cs-CS" src="/flags/cs-CS.jpg" width="30" height="20" />
          <span name="cs-CS">čeština</span>
        </a>
      </div>
    </div>
  );
}
