import React from "react";
import ShiftTable from "../../Components/Tables/ShiftTable";
import ShiftReportTable from "../../Components/Tables/ShiftReportTable";
function ShiftReports({ title }) {
  return (
    <div className="shift-reports">
      <div className="row content-header">
        <div className="col-12">
          <div className="page-title">
            <h5>{title}</h5>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="row col-md-7 col-lg-8 no-margin no-padding">
          <div className="col-md-12 col-lg-6">
            <ShiftReportTable />
          </div>
          <div className="col-md-12 col-lg-6">
            <ShiftReportTable />
          </div>
          <div className="col-md-12 col-lg-6">
            <ShiftReportTable />
          </div>
          <div className="col-md-12 col-lg-6">
            <ShiftReportTable />
          </div>
        </div>
        <div className="col-md-5 col-lg-4 row no-margin no-padding">
          <div className="col-12">
            <ShiftTable />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShiftReports;
