import React, { useRef, useContext } from 'react'
import { ParsApi } from '../Api/ParsApi';
import DataContext from '../../Contexts/DataContext';
import Modal from '../SeedWorks/Modal';
export default function ProfileUpdateForm({ user }) {
    const { text } = useContext(DataContext)
    const formRef = useRef();
    const { parsApi } = ParsApi();

    const complateRegister = async (e) => {
        e.preventDefault();
        var formdata = Object.fromEntries(new FormData(formRef.current).entries());
        try {
            parsApi.updateProfile(formdata).then(() => {
                window.$("#successful").modal("show");
            });

        } catch (error) {
            window.$("#unsuccessful").modal("show");

        }

    }

    const passwordValidation = () => {
        const input1 = window.$("#password")[0];
        const input2 = window.$("#passwordAgain")[0];
        const error = window.$("#error")[0];
        const button = window.$("#complate")[0];

        if (input1.value === input2.value) {
            error.classList.add("d-none")
            button.disabled = false;
        }
        else {
            error.classList.remove("d-none")
            button.disabled = true;
        }
    }
    return (
        <form className='register-form' ref={formRef} onSubmit={complateRegister} onInput={passwordValidation}>
            <div className="form-group">
                <label>{text("UserName")}</label>
                <input required readOnly defaultValue={user.userName} autoComplete="name" type="text" />
            </div>

            <div className="form-group">
                <label>{text("Name")}</label>
                <input required name="Name" defaultValue={user.name} type="text" />
            </div>

            <div className="form-group">
                <label>{text("User_Title")}</label>
                <input type="text" autoComplete='title' defaultValue={user.title} name="Title" />
            </div>

            <div className="form-group">
                <label>{text("E_Mail")}</label>
                <input type="text" autoComplete='email' defaultValue={user.mail} name="Mail" />
            </div>

            <div className="form-group">
                <label>{text("Phone_Number")}</label>
                <input type="text" autoComplete='phone' defaultValue={user.phone} name="Phone" />
            </div>

            <div className="form-group">
                <label>{text("New_Password")}</label>
                <input id='password' autoComplete='password' type="password" />
                <label htmlFor="" id='error' className='d-none text-danger'>{text("Passwords_Do_Not_Match")}</label>
            </div>

            <div className="form-group">
                <label>{text("Re-Enter_Your_Password")}</label>
                <input id='passwordAgain' name="Password" type="password" />
            </div>

            <button id='complate' className='btn btn-success btn-right'>{text("Save")}</button>
            <Modal id="successful" content={() => text("Profile_Updating_Process_Completed")} title={text("Changes_Saved")} footerVisible={true} event={()=>{window.location.href="/";}} />
            <Modal id="unssuccessful" content={() => text("Profile_Updating_Process_Not_Completed")} title={text("Changes_Not_Saved")} footerVisible={false} />
        </form>
    )
}
