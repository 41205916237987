import React, { useContext, useRef } from "react";
import DataContext from "../../Contexts/DataContext";
import ApiContext from "../../Contexts/ApiContext"
import { ParsApi } from "../Api/ParsApi";
import PreLoader from "../SeedWorks/PreLoader";
import E401 from "../SeedWorks/Errors/E401";
import E404 from "../SeedWorks/Errors/E404";
import E500 from "../SeedWorks/Errors/E500";
export default function ReportForm() {

  const { text } = useContext(DataContext);
  const { machines } = useContext(ApiContext);
  const { parsApi } = ParsApi();
  const formRef = useRef(); // Form refarance

  const createReport = (e) => {
    e.preventDefault();
    var formdata = Object.fromEntries(new FormData(formRef.current).entries());
    formdata.MachineId = parseInt(formdata.MachineId);
    parsApi.createReport(formdata).then((response) => {
      if (response.status == 200) {
        window.location.reload(false);
      }
    });

  }

  if (machines == null) {
    return <PreLoader />
  }
  else if (machines.response.code != 200) {
    switch (machines.response.code) {
      case 401:
        return <E401 />;
      case 404:
        return <E404 />;
      case 501:
        return <E500 />;
      default:
        return <E500 />;
    }
  }
  else{
    const machinesData = machines.response.data
    return (
      <div className="card">
        <div className="card-title">{text("Create_Report")}</div>
        <form onSubmit={createReport} className="report-form" ref={formRef}>
          <div className="form-group">
            <label htmlFor="machines">{text("Machine")}</label>
            <select id="machines" type="number" name="MachineId">
              {machinesData.map((machine) => {
                return (
                  <option key={machine.id} value={machine.id} type="number">{machine.name}</option>
                )
              })}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="reportName">{text("Report_Name")}</label>
            <input id="reportName" autoComplete="none" placeholder={text("Report_Name")} required name="ReportName" />
          </div>

          <div className="formHidden">
            <div className="form-group">
              <label htmlFor="startTime">{text("Start_Time")}</label>
              <input id="startTime" type="datetime-local" name="StartTime" required placeholder="" />
            </div>

            <div className="form-group">
              <label htmlFor="endTime"> {text("Stop_Time")} </label>
              <input id="endTime" type="datetime-local" name="EndTime" required placeholder="" />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="mailAddress">{text("E_Mail_Address")}</label>
            <input id="mailAddress" autoComplete="email" name="email" placeholder="" />
          </div>

          <button type="submit" className="btn btn-warning">{text("Save")}</button>
        </form>
      </div>
    );

  }
    
}
