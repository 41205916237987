import React, { useContext, useState } from "react";
import DataContext from "../../Contexts/DataContext";
import Machine from "./Rows/Machine";
import Modal from "../SeedWorks/Modal"
import EditMachineNameForm from "../Forms/EditMachineNameForm";

function MachineTable({ machines }) {
  const { text } = useContext(DataContext);
  const [changingMachineId, setChangingMachineId] = useState(null);
  const [machineName, setMachineName] = useState(null)
  return (
    <div className="card">
      <div className="card-title">{text("Machine_List")}</div>
      <div className="card-content">
        <table className="tableMachineList responsive-table">
          <thead>
            <tr className="trhead">
              <th>{text("State")} </th>
              <th> {text("Name")} </th>
              <th> {text("Actual_Capacity")} </th>
              <th> {text("Target_Capacity")} </th>
              <th className="text-center"> {text("Edit")} </th>
            </tr>
          </thead>
          <tbody>
            {machines.map((machine) => {
              return <Machine key={machine.id} machine={machine} changeName={setMachineName} changeEvent={setChangingMachineId} />
            })}

          </tbody>
        </table>
      </div>

      <Modal
        id="modalChangeMachine"
        content={() => EditMachineNameForm(changingMachineId,machineName)}
        title={text("Change_Machine_Name")}
        footerVisible={false}

      />
    </div>
  );
}

export default MachineTable;
