import React from "react";
import ReactApexChart from "react-apexcharts";
export default function DonutChart({series, labels}) {
  const options = {
    chart: {
      type: "radialBar",
      animations: {
        enabled: false}
    },
    legend: {
      show: true,
      position: "bottom",
    },
    

    dataLabels: {
      enabled: !0,
      enabledOnSeries: [1],
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: false,
              show: false,
            },
          },
        },
      },
    },
    colors: [
      "#1abc9c",
      "#343a40",
      "#E63946",
      "#1D3557",
      "#ffd145",
      "#495057",
      "#457B9D",
    ],
    labels: labels,
  };

  return (
    <div id="chart">
      <ReactApexChart height={350} options={options} series={series} type="donut" />
    </div>
  );
}
