import React, { useContext } from "react";
import RawMaterial from "./Rows/RawMaterial";
import DataContext from "../../Contexts/DataContext";
import uuid from 'react-uuid';
export default function RawMaterialTable({ rawMaterials }) {
  const { text } = useContext(DataContext);
  return (
    <div className="card">
      <div className="card-title">{text("Recipe_Information")}</div>
      <table className="responsive-table">
        <thead>
          <tr>
            <th>{text("Raw_Material_Name")}</th>
            <th>{text("Rate")}</th>
            <th>{text("Specific_Gravity")}</th>
            <th>{text("Total_Consumption")}</th>
            <th>{text("Cost")} ({text("kg")})</th>
            <th>{text("Consumption_Cost")}</th>
          </tr>
        </thead>
        <tbody>
          {rawMaterials?.map((raw,index) => {
            return (<RawMaterial key={uuid()}  rawMaterial={raw} index={index +1} />)
          })}
        </tbody>
      </table>
    </div>
  );
}
