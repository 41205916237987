import React, { useContext, useRef } from "react";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataContext from "../../../Contexts/DataContext";
import ApiContext from "../../../Contexts/ApiContext";
import { ParsApi } from "../../Api/ParsApi";
export default function RawMaterialCost({ cost, machineId }) {
  const { text,setCost } = useContext(DataContext);
  const { currencies } = useContext(ApiContext);
  const { parsApi } = ParsApi();
  const rawName = useRef();
  const rawCost = useRef();
  const rawCurrency = useRef();

  const updateCost = (e) => {
    e.preventDefault();
    const rawData = {
      Name: rawName.current.value,
      Cost: parseFloat(rawCost.current.value),
      CurrencyUnit: rawCurrency.current.value,
      MachineId: machineId
    }
    setCost(rawData);
    parsApi.setRawMaterialCost(rawData,false);
    
    
  }
  return (
    <tr>
      <td data-label={text("Raw_Material_Name")} className="text-bold">
        <input type="text" ref={rawName} disabled={true} defaultValue={cost.name} />
      </td>
      <td data-label={text("Cost")} className="text-warning text-bold">
        <input type="text" defaultValue={cost.cost} ref={rawCost} />
      </td>
      <td data-label={text("Currency")}>
        <select name="" id="" defaultValue={cost.currencyUnit} ref={rawCurrency}>
          {currencies.map((currency) => <option key={rawName+currency.code} value={currency.code}>{currency.code}</option>)}
        </select>
      </td>
      <td className="text-right">
        <a href="" onClick={updateCost} className="btn btn-sm btn-warning mr-10">
          <FontAwesomeIcon icon={Icons.faEdit} />
        </a>
      </td>
    </tr>
  );
}
