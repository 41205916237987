import React, { useContext } from "react";
import DataContext from "../../Contexts/DataContext";
import UserReport from "../../Components/Tables/Rows/UserReport";

export default function ReportTable({reports}) {
  const { text } = useContext(DataContext);
  return (
    <div className="card">
      <div className="card-title">{text("Previous_Reports")}</div>
      <table className="responsive-table">
        <thead>
          <tr>
            <th>{text("Report_Name")}</th>
            <th>{text("Machine_Name")}</th>
            <th>{text("Creation_Time")}</th>
            <th className="text-right"></th>
          </tr>
        </thead>
        <tbody>
          {reports.map((report)=><UserReport key={report.id} report={report} />)}
        </tbody>
      </table>
    </div>
  );
}
