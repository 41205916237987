import React, { useContext } from "react";
import MachineScreen from "../Components/Cards/MachineScreen";
import ApiContext from "../Contexts/ApiContext";
import Preloader from "../Components/SeedWorks/PreLoader";
import E401 from "../Components/SeedWorks/Errors/E401";
import E404 from "../Components/SeedWorks/Errors/E404";
import E500 from "../Components/SeedWorks/Errors/E500";
export default function Screens({ title }) {
  const { machines } = useContext(ApiContext);
  if (machines === null) {
    return <Preloader />;
  } else if (machines.response.code !== 200) {
    switch (machines.response.code) {
      case 401:
        return <E401 />;
      case 404:
        return <E404 />;
      case 501:
        return <E500 />;
      default:
        return <E500 />;
    }
  } else {
    const machinesData = machines?.response?.data;
    return (
      <div className="screens">
        <div className="row content-header">
          <div className="col-12">
            <div className="page-title">
              <h5>{title}</h5>
            </div>
          </div>
        </div>
        <div className="row">
          {machinesData.map((machines) => {
            
            return (
              <div key={machines.id} className="col-md-4 col-lg-3 ">
                <MachineScreen  machine={machines} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
