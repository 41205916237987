import React, { useContext, useEffect, useRef, useState } from "react";
import Language from "../../Components/SeedWorks/Language";
import DataContext from "../../Contexts/DataContext";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faArrowLeft,faSpinner } from "@fortawesome/free-solid-svg-icons";
import logo from "../../Images/logo-dark.png";
import "./Login.css";
import App from "../../App";
import PreLoader from "../../Components/SeedWorks/PreLoader";
function Login() {
  const { text, cookies, setCookies } = useContext(DataContext);

  const [step, setStep] = useState(0);
  const [loginStatus, setLoginStatus] = useState(null);
  const [spin,setSpin] = useState(false)

  const [errorStatus, setErrorStatus] = useState(0);
  const apiURL = "http://service.pars-connect.com/Authorize/";

  const formRef = useRef(); // Form refarance


  //Login Toggle Status


  useEffect(() => {
    CheckSession();
  });
  const CheckSession = async () => {
    try {
      if (cookies.session !== undefined) {
        await axios
          .get(apiURL + 'ValidateToken/', {
            params: {
              token: cookies.session
            },
          }).then((res) => {
            setLoginStatus(res.data);
          });
      }
      else{
        setLoginStatus(false);
      }
    } catch (error) {
      setLoginStatus(false);

    }
  }

  const Login = async (e) => {
    e.preventDefault();
    if (step === 0) {
      setStep(1);
      window.$("#password")[0].focus();
    }
    else {
      try {
        await axios
          .get(apiURL + 'Auth/', {
            params: Object.fromEntries(new FormData(formRef.current).entries()),
          })
          .then((res) => {
            setSpin(true)
            setCookies("session", res.data)
            window.location.reload();
          });
      } catch (error) {
        var status = error.response.status;
        if (status === 401) {
          setErrorStatus(401);
        }
        else {
          setErrorStatus(500);
        }

      }
    }
  }

  if (loginStatus === null) {
    return (<PreLoader />)
  }
  else
    if (loginStatus) {
      return (<App />)
    }
  return (
    <div  className="login">
      <div className="login-bg" />
      <div className="login-from">
        <div className="logo-possition"><img className="login-form-logo" src={logo} alt="Pars_Logo" /></div>
        <form onSubmit={Login} className="form" ref={formRef}>
          <div className="form-header-text">
            <h1>{text("Login")}</h1>
            <Language />
          </div>
          <div className={`login-username ${step === 0 ? "" : "close"}`}>
            <div className="input-button-possition">
              <input tabIndex={-1} pattern="[^\s]+" id="username" name="username" autoComplete="name" required placeholder={text("UserName")} type="text" />
              <p>{text("Don't_have_an_account?")}<a>{text("Create_a_new_account!")}</a></p>
              <a>{text("Can't_access_your_account?")}</a>
            </div>
            <button type={`submit`} tabIndex={-1} className="continue-button btn btn-warning">{text("Forward")}</button>
          </div>
          <div className={`login-password  ${step === 1 ? "" : "close"}`}>
            <div className="input-button-possition">
              <span style={{ color: "red" }}>
                {
                  errorStatus === 401 ? text("401T") : errorStatus === 500 ? text("500T") : ""
                }
              </span>
              <input id="password"  tabIndex={-1}  name="password" required={step === 0 ? false : true} autoComplete="current-password" placeholder={text("Password")} type={"password"} />
            </div>
            <button type="submit"  tabIndex={-1} id="submit-button"  className="submit-button">{text("Login")} {spin ? <FontAwesomeIcon className="spinner" icon={faSpinner} />:null}</button>
            <FontAwesomeIcon className="login-back" onClick={() => { setStep(0) }} icon={faArrowLeft} />
          </div>
        </form>
      </div>
    </div>
  );
}
export default Login;
