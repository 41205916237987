import React, { useContext, useRef, useState, useEffect } from "react";
import DataContext from "../../Contexts/DataContext";
function MachineScreen({machine}) {
  const [screenHeight, setScreenHeight] = useState(0);
  const [fontSize, setFontSize] = useState(0);
  const screenRef = useRef(null);

  useEffect(() => {
    const screenWidth = screenRef.current.clientWidth;

    setScreenHeight(`${(screenWidth / 16) * 9}px`);
    setFontSize(`${(screenWidth / 2.1) * 0.043 + 2}px`);

    function handleResize() {
      const screenWidth = screenRef.current.clientWidth;

      setScreenHeight(`${(screenWidth / 16) * 9}px`);
      setFontSize(`${(screenWidth / 2.1) * 0.043 + 2}px`);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const { text } = useContext(DataContext);
  return (
    <div className="card">
      <div className="card-title">{machine.name}</div>
      <div
        className="screenArea"
        ref={screenRef}
        style={{
          backgroundImage: "url(screen.png)",
          height: `${screenHeight}`,

          
        }}
      >
        <div className="screen">
     
          <div className="top-side">
            <img className="screen-logo" src="/logolight.png" alt="" />
          </div>
            <div className="screen-mask" />
          <div className="bottom-side">
            <div className="bottom-left-side" style={{ fontSize: `${fontSize}` }}>
              <div className="screen-text-box screen-title">
                <i />
                {text("Capacity")}
              </div>
              <div className="screen-text-box screen-title">
                <i />
                {text("Product_Weight")}
              </div>
              <div className="screen-text-box screen-title">
                <i />
                {text("Thickness")}
              </div>
              <div className="screen-text-box screen-title">
                <i />
                {text("Line_Speed")}
              </div>
              <div className="screen-text-box screen-title">
                <i />
                {text("Film_Width")}
              </div>
            </div>
            <div className="bottom-right-side" style={{ fontSize: `${fontSize}` }}>
              <div className="screen-text-box screen-data">
                <i />
                {machine.isConnected?machine.machineData.activeCapacity.toFixed(2):0} <span>{text("kg/h")}</span>
              </div>
              <div className="screen-text-box screen-data">
                <i />
                {machine.isConnected?machine.machineData.activeGrammage.toFixed(2):0} <span>{text("gr/m")}</span>
              </div>
              <div className="screen-text-box screen-data">
                <i />
                {machine.isConnected?machine.machineData.activeThickness.toFixed(2):0} <span> {text("µ")} </span>
              </div>
              <div className="screen-text-box screen-data">
                <i />
                {machine.isConnected?machine.machineData.activeLineSpeed.toFixed(2):0} <span>{text("m/min")}</span>
              </div>
              <div className="screen-text-box screen-data">
                <i />
                {machine.isConnected?machine.machineData.filmWith.toFixed(0):0} <span>{text("mm")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MachineScreen;
