import { useContext } from "react";
import axios from "axios";
import DataContext from "../../Contexts/DataContext";
export const ParsApi = () => {
  const { cookies } = useContext(DataContext);
  const api = axios.create({
    baseURL: "http://iot.pars-connect.com/v1/",
    headers: {
      Authorization: `Bearer ${cookies.session}`,
    },
  });

  /*================================== Get ========================================= */
  const getUser = async () => {
    if (cookies.session !== undefined) {
      return await api.get("GetUser");
    } else {
      return null;
    }
  };
  const getMachines = async (setMachines) => {
    try {
      return await api.get("GetMachines").then((response) => {
        setMachines({
          response: {
            code: response.status,
            data: response.data,
          },
        });
      });
    } catch (error) {
      setMachines({
        response: {
          code: error.response.status,
          data: null,
        },
      });
    }
  };
  const getMachine = async (id, setMachine) => {
    try {
      return await api.get("GetMachine?machineId=" + id).then((response) => {
        setMachine({
          response: {
            code: response.status,
            data: response.data,
          },
        });
      });
    } catch (error) {
      setMachine({
        response: {
          code: error.response.status,
          data: null,
        },
      });
    }
    if (cookies.session !== undefined) {
    } else {
      return null;
    }
  };
  const getCustumer = async () => {
    if (cookies.session !== undefined) {
      return await api.get("GetCustomer");
    } else {
      return null;
    }
  };

  const getCurrenies = async () => {
    if (cookies.session !== undefined) {
      return await api.get("GetCurrenies");
    } else {
      return null;
    }
  };
  const getReports = async (setData) => {
    try {
      return await api.get("GetReports").then((response) => {
        setData({
          response: {
            code: response.status,
            data: response.data,
          },
        });
      });
    } catch (error) {
      setData({
        response: {
          code: error.response.status,
          data: null,
        },
      });
    }
  };
  const getReport = async (id, setData,setReportData) => {
    try {
      return await api.get("GetReport?reportId=" + id).then((response) => {
        setData({
          response: {
            code: response.status,
            data: response.data,
          },
        });
        setReportData(JSON.parse(response.data.data));
      });
    } catch (error) {
      setData({
        response: {
          code: error.response.status,
          data: null,
        },
      });
    }
    if (cookies.session !== undefined) {
    } else {
      return null;
    }
  };

  const getOrders = async (setData) => {
    try {
      return await api.get("GetOrders").then((response) => {
        setData({
          response: {
            code: response.status,
            data: response.data,
          },
        });
      });
    } catch (error) {
      setData({
        response: {
          code: error.response.status,
          data: null,
        },
      });
    }
  };

  const getOrder = async (machineId, orderId,setData) => {
    try {
      return await api.get(`GetOrder?machineId=${machineId}&orderId=${orderId}`).then((response)=>{
        setData({
          response:{
            code : response.status,
            data:response.data
          }
        })
      })
    } catch (error) {
      setData({
        response:{
          code: error.response.status,
          data: null
        }
      })
      
    }
    
  };
  const getRawMaterialCosts = async (setCosts) => {
    try {
      return await api.get("GetRawMaterialCosts").then((response) => {
        setCosts({
          response: {
            code: response.status,
            data: response.data,
          },
        });
      });
    } catch (error) {
      setCosts({
        response: {
          code: error.response.status,
          data: null,
        },
      });
    }
  };

  const createReport = async (data) => {
    return await api.post("CreateReport", data);
  };

  const setRawMaterialCost = async (data,allMachines) => {
    var response = null;
    try {
      response = await api.post("SetRawMaterialCosts?allMachines="+allMachines, data);
      if(allMachines)
      {
        $("#rawSavedAll").modal("show");
      }
      else
      {
        $("#rawSaved").modal("show");
      }
    } catch (error) {
      $("#rawNotSaved").modal("show");
    }

    return response;
  };

  const deleteReport = async (reportId) => {
    return await api.delete("DeleteReport?reportId=" + reportId);
  };

  const changeMachineName = async (data) => {
    $("#modal").modal("toggle");
    return await api.put("updateMachine", data);
  };
  const updateProfile = async (data) => {
    return await api.put("updateProfile", data);
  };

  const $ = window.$;

  /*================================== Post ========================================= */

  const parsApi = {
    getUser,
    getMachines,
    getMachine,
    getCustumer,
    getCurrenies,
    getReports,
    getReport,
    getOrders,
    getOrder,
    getRawMaterialCosts,
    createReport,
    setRawMaterialCost,
    deleteReport,
    changeMachineName,
    updateProfile,
  };

  return { parsApi };
};
