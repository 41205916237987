import React from "react";
import ReactApexChart from "react-apexcharts";
export default function BarChart({ datas }) {

  const series = [
    {
      name: "Tuketim (kg)",
      data: datas,
    },
  ];
  const options = {
    chart: {
      type: "bar",
    },
    colors: ["#ffc80e"],
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
      style:{
        colors:["#636668"],
        fontSize: '14px', 
        fontWeight: 'bold', 
      }
    },
   
  };
  return (
    <div  id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={75 + (series[0].data.length) * 50}

      />
    </div>
  );
}
