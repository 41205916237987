import React, { useContext } from "react";
import DataContext from "../../Contexts/DataContext";
import ShiftOrder from "./Rows/ShiftOrder";

export default function ShiftReportTable() {
  const { text } = useContext(DataContext);

  return (
    <div className="card">
      <div className="card-title">Makine 1</div>
      <table className="responsive-table">
        <thead>
          <tr>
            <th>{text("Shift")}</th>
            <th>{text("Start_Time")}</th>
            <th>{text("Stop_Time")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ShiftOrder/>
          <ShiftOrder/>
          <ShiftOrder/>
          <ShiftOrder/>
        </tbody>
      </table>
    </div>
  );
}
