import React, { useContext, useState, useEffect } from "react";
import ExtruderAccordion from "../Components/Accordion/ExtruderAccordion";
import ExtruderRatioCard from "../Components/Cards/ExtruderRatioCard";
import FilmWidthCard from "../Components/Cards/FilmWidthCard";
import MachineDataCard from "../Components/Cards/MachineDataCard";
import OperationTimeCard from "../Components/Cards/OperationTimeCard";
import RecipeTable from "../Components/Tables/RecipeTable";
import DataContext from "../Contexts/DataContext";
import { useParams } from "react-router-dom";
import { ParsApi } from "../Components/Api/ParsApi";
import PreLoader from "../Components/SeedWorks/PreLoader";
import PrintButton from "../Components/SeedWorks/PrintButton";
import E401 from "../Components/SeedWorks/Errors/E401";
import E404 from "../Components/SeedWorks/Errors/E404";
import E500 from "../Components/SeedWorks/Errors/E500";

export default function MachineDetail() {
  const { parsApi } = ParsApi();
  const { id } = useParams();
  const { text } = useContext(DataContext);
  const [machine, setMachine] = useState(null);
  document.addEventListener("keydown", function (event) {
    if (event.ctrlKey && event.code === "KeyP") {
      var collapses = window.$(".collapse");
      for (let index = 0; index < collapses.length; index++) {
        collapses[index].classList.remove("collapse");
      }
    }
  });
  useEffect(() => {
    const fetchData = async () => {
      parsApi.getMachine(id,setMachine);
    };
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 15000);
    return () => clearInterval(interval);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (machine === null) {
    return <PreLoader />;
  }else if (machine.response.code !== 200) {
    switch (machine.response.code) {
      case 401:
        return <E401 />;
      case 404:
        return <E404 />;
      case 501:
        return <E500 />;
      default:
        return <E500 />;
    }
  }
   else {
    const machineData = machine?.response?.data;
    const operationTime =  machineData.operationTime;
    const date = new Date(`1970-01-01T${operationTime.replace("-","")}`);
    let hour = Math.abs(isNaN(date.getHours()) ? 0 :date.getHours() );
    let minute = date.getMinutes();
    let [manipuleHour,manipuleMinute] = operationTime.split(":")
    let manipuleHour2 = manipuleHour.split(".")
    if( manipuleHour2.length >1){
      hour =  parseInt(manipuleHour2[0]*24) +parseInt(manipuleHour2[1])
      minute = manipuleMinute 
    }
    if(machineData.machineData.activeCapacity === 0){
      hour = 0;
      minute = 0;
    }
    return (
      <div className="machine-detail">
        <div className="row content-header">
          <div className="col-sm-12">
            <div className="page-title">
              <h5 style={{fontWeight:"bold"}}>{machineData.name}</h5>
            </div>
          </div>
        </div>
        <div className="row print-card ">
          <div className="col-sm-4 col-md-6 col-lg-4">
            <MachineDataCard
              title={text("Actual_Capacity")}
              target={machineData.machineData.targetCapacity}
              active={machineData.machineData.activeCapacity}
              unit={text("kg/h")}
            />
          </div>

          <div className="col-sm-4 col-md-6 col-lg-4">
            <MachineDataCard
              title={text("Actual_Weight")}
              target={machineData.machineData.targetGrammage}
              active={machineData.machineData.activeGrammage}
              unit={text("gr/m")}
            />
          </div>

          <div className="col-sm-4 col-md-6  col-lg-4">
            <MachineDataCard
              title={text("Actual_Thickness")}
              target={machineData.machineData.targetThickness}
              active={machineData.machineData.activeThickness}
              unit={text("µ")}
            />
          </div>

          <div className="col-sm-4 col-md-6  col-lg-4">
            <MachineDataCard
              title={text("Line_Speed")}
              target={machineData.machineData.targetLineSpeed}
              active={machineData.machineData.activeLineSpeed}
              unit={text("m/min")}
            />
          </div>

          <div className="col-sm-4 col-md-6  col-lg-4">
            <FilmWidthCard
              title={text("Film_Width")}
              value={machineData.machineData.filmWith}
              unit={text("mm")}
            />
          </div>

          <div className="col-sm-4 col-md-6  col-lg-4">
            <OperationTimeCard
              hour={hour }
              minute={minute}
              lastDate={machineData.machineData.time}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-8 col-md-8">
            <RecipeTable
              rawMaterials={machineData.recipe}
              kgCosts={machineData.kgCosts}
            />
          </div>

          <div className="col-sm-4 col-md-4">
            <ExtruderRatioCard
              extrudersRatio={machineData.machineData.extruderData.map(
                (extruder) => extruder.targetRatio
              )}
              extrudersName={machineData.machineData.extruderData.map(
                (extruder) =>
                  `${text("Extruder")} ${String.fromCharCode(65 + extruder.no)}`
              )}
            />
          </div>
        </div>

        {machineData.machineData.extruderData.map((extruder) => {
          
          return (    
          <ExtruderAccordion
            key={extruder.id}
            extruder={extruder}
            costs={machineData.extruderCosts}
          />)
        })}
        <PrintButton />
      </div>
    );
  }
}
