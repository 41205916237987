import React, { useContext } from "react";
import DataContext from "../../Contexts/DataContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Language from "./Language";
import { Link } from "react-router-dom";
import ApiContext from "../../Contexts/ApiContext";
import { useCookies } from 'react-cookie';


export default function Header() {
  const { settings, setSettings, text } = useContext(DataContext);
  const [ ,removeCookie] = useCookies(['session']);
  const {user} = useContext(ApiContext)
  const toggleSidebar = () => {
    setSettings({ ...settings, sidebarToggle: !settings.sidebarToggle });
  };
  const logOut = () => { 
    removeCookie('session')
  };


  return (
    <nav className="navbar  navbar-light">
      <div className="container-fluid">
        <div className="navbar justify-content-between">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="toggleIcon" onClick={toggleSidebar}>
                <FontAwesomeIcon icon={faBars} />
              </a>
            </li>
          </ul>
          <div className="navbar-right">
            <Language />
            <div className="dropdown">
              <a href="#" className="d-block text-decoration-none dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false">
                <img src="/user-avatar.png" alt="mdo" width="32" height="32" className="rounded-circle" />
              </a>
              <div className="dropdown-menu">
                <p  className="dropdown-user">{(user?.userName)?.toUpperCase()}</p>
                <Link to={"/updateProfile"} className="dropdown-item" href="#">{text("Profile_Update")}</Link>
                <a type="button" onClick={logOut} className="dropdown-item" href="#">{text("LogOut")}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
