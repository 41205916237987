import React from "react";

export default function FilmWidthCard({ title, value, unit }) {
  return (
    <div className="card data-card">
      <div className="data-title">{title}</div>
      <div className="data-value">
        {value}
        <span> {unit}</span>
      </div>
      <div className="data-sets justify-content-between d-flex">
        <span>{"-"}</span>
      </div>
    </div>
  );
}
