import React, { useContext } from "react";
import DataContext from "../../../Contexts/DataContext";

export default function RawMaterial({rawMaterial ,index}) {
  const { text } = useContext(DataContext);
  if(rawMaterial.ratio!==0){
  return (
    <tr>
      <td data-label={text("Raw_Material_Name")}>{rawMaterial.name}{index}</td>
      <td data-label={text("Rate")}>{rawMaterial.ratio.toFixed(0)} %</td>
      <td data-label={text("Total_Consumption")}>{rawMaterial.specificWeight.toFixed(3)} {text("gr/cm³")}</td>
      <td data-label={text("Total_Consumption")}>{rawMaterial.totalConsumption.toFixed(2)} {text("kg")}</td>
      <td data-label={text("Cost")}>{rawMaterial.cost} {rawMaterial.currencyUnit}</td>
      <td data-label={text("Consumption_Cost")}>{rawMaterial.consumptionCost.toFixed(2)} {rawMaterial.currencyUnit}</td>
    </tr>
  );}
}
