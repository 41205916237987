import React from "react";
import ReactApexChart from "react-apexcharts";
function CapacityChart({text, value, name, color}) {

  const options = {
    chart: {
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        
      }
    },
    fill: {
      gradient: {
        enabled: !0,
        shade: "dark",
        shadeIntensity: .15,
        inverseColors: !1,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 65, 91]
      }
    },
    responsive: [
      {
        breakpoint: 765,
        options: {
          chart: {
            height: 300
          }
        }
      },
      
      {
        breakpoint: 1400,
        options: {
          chart: {
            height: 150
          }
        }
      },
      
      {
        breakpoint: 2000,
        options: {
          chart: {
            height: 150
          }
        }
      },
      
   
    ],
    colors: [color],
    series: [67],
    labels: [text]
  };
  return (
    <div id={name}>
      <ReactApexChart
        options={options}
        series={[value]}
        type="radialBar"
      />
    </div>
  );
}

export default CapacityChart;
