import React, { useContext } from 'react'
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataContext from "../../../Contexts/DataContext";
import { Link } from 'react-router-dom';
import { ParsApi } from '../../Api/ParsApi';
export default function UserReport({ report }) {
    const { parsApi } = ParsApi();
    const { text,dateFormat } = useContext(DataContext);

    const DeleteReport = (id) => {
        parsApi.deleteReport(id);
    }
    return (
        <tr>
            <td data-label={text("Report_Name")}>{report.name}</td>
            <td data-label={text("Machine_Name")}>{report.machine.name}</td>
            <td data-label={text("Creation_Time")}>{dateFormat(report.creationTime)}</td>
            <td data-label={text("Edit")} className="text-right">
                <Link to={`/machineReport/${report.id}`} className="btn btn-sm btn-success mr-10">
                    <FontAwesomeIcon icon={faEye} />
                </Link>
                <a href="" onClick={() => {
                    DeleteReport(report.id)
                }} className="btn btn-sm btn-danger">
                    <FontAwesomeIcon icon={faTrash} />
                </a>
            </td>
        </tr>
    )
}
