import React, { useContext } from "react";
import DataContext from "../../Contexts/DataContext";
import uuid from "react-uuid";

export default function CostCard({ costs, extruderNo }) {
  const { text } = useContext(DataContext);
  return (
    <div className="card cost-card">
      <div className="data-title">
        {text("Mix_Cost")} ({text("kg")})
      </div>
      <table className="cost-table">
        <tbody>
          {costs[extruderNo].cost.length === 0 ? (
            <>
              <tr>
                <td key={uuid()}><span className="data-value">0 <span>TRY</span></span></td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                {costs[extruderNo].cost.map((cost) => {
                  return (<td key={uuid()}><span className="data-value">{cost.price.toFixed(2)} <span>{cost.currency}</span></span></td>);
                })}
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
}
