import React, { useContext, useState, useEffect } from "react";
import ReportForm from "../../Components/Forms/ReportForm";
import ReportTable from "../../Components/Tables/ReportTable";
import DataContext from "../../Contexts/DataContext";
import { ParsApi } from "../../Components/Api/ParsApi";
import PreLoader from "../../Components/SeedWorks/PreLoader";
import E401 from "../../Components/SeedWorks/Errors/E401";
import E404 from "../../Components/SeedWorks/Errors/E404";
import E500 from "../../Components/SeedWorks/Errors/E500";
function UserReports({ title }) {
  
  const { text } = useContext(DataContext);
  const { parsApi } = ParsApi();
  const [reports, setReports] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      parsApi.getReports(setReports)
    };
    fetchData();
  }, []);

  if(reports==null)
  {
    return <PreLoader/>
  }else if(reports.response.code !== 200){
    switch (reports.response.code) {
      case 401:
        return <E401 />;
      case 404:
        return <E404 />;
      case 501:
        return <E500 />;
      default:
        return <E500 />;
    }
  }
  else{
    const reportsData = reports.response.data
    return (
      <div className="repotrs">
        <div className="row content-header">
          <div className="col-12">
            <div className="page-title">
              <h5>{title}</h5>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <ReportForm text={text} />
          </div>
          <div className="col-md-8 col-lg-5">
            <ReportTable text={text} reports={reportsData} />
          </div>
        </div>
      </div>
    );

  }
  
}

export default UserReports;
