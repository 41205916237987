import React, { useContext } from "react";
import DataContext from "../../../Contexts/DataContext";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function ShiftOrder() {
  const { text } = useContext(DataContext);
  return (
    <tr>
      <td data-label={text("Shift")} className="text-bold">
        Vardiya 1
      </td>
      <td data-label={text("Start-Time")}>02/25/2023 08:00</td>
      <td data-label={text("Stop-Time")}>02/25/2023 08:00</td>
      <td className="text-right">
        <a href="" className="btn btn-sm btn-success mr-10">
          <FontAwesomeIcon icon={faEye} />
        </a>
      </td>
    </tr>
  );
}
