import React, { useContext } from "react";
import Dahsboard from "../../Pages/Dahsboard";
import Screens from "../../Pages/Screens";
import Livedata from "../../Pages/Livedata";
import UserReports from "../../Pages/Reports/UserReports";
import ShiftReports from "../../Pages/Reports/ShiftReports";
import ProductReports from "../../Pages/Reports/ProductReports";
import Costs from "../../Pages/Costs";
import { Route, Routes } from "react-router";
import DataContext from "../../Contexts/DataContext";
import MachineDetail from "../../Pages/MachineDetail";
import MachineReport from "../../Pages/MachineReport";
import ProductReport from "../../Pages/ProductReport";
import PreLoader from "./PreLoader";
import UpdateProfile from "../../Pages/UpdateProfile";

export default function Container() {
  const { text,settings,setSettings} = useContext(DataContext)
  const toggleSidebar = () => {
    if (window.innerWidth <= 748) {
      setSettings({ ...settings, sidebarToggle: false });
    }
  };

    return (
      <div onClick={toggleSidebar} className={`content-page`}>
        <div className="content">
          <div id="printarea" className="container-fluid">
            <Routes>
              <Route path="/" element={<Dahsboard title={text("My_Machines")} />} />
              <Route path="/screens" element={<Screens title={text("My_Screens")} />} />
              <Route path="/livedata" element={<Livedata title={text("Live_Datas")} />} />
              <Route path="/userReports" element={<UserReports title={text("Reports")} />} />
              <Route path="/shiftReports" element={<ShiftReports title={text("Shift_Reports")} />} />
              <Route path="/productReports" element={<ProductReports title={text("Production_Reports")} />} />
              <Route path="/costs" element={<Costs title={text("Raw_Material_Cost")} />} />
              <Route path="/preloader" element={<PreLoader title={text("Raw_Material_Cost")} />} />
              <Route path="/machineDetail/:id" element={<MachineDetail />} />
              <Route path="/machineReport/:id" element={<MachineReport />} />
              <Route path="/productReport/:id" element={<ProductReport />} />
              <Route path="/updateProfile" element={<UpdateProfile />} />
            </Routes>
          </div>
        </div>
      </div>
    );
}
