import React,{useContext} from 'react'
import { Link } from "react-router-dom";
import DataContext from '../../../Contexts/DataContext'
export default function E500() {
  const {text} = useContext(DataContext)
  return (
    <div className="response-error ">
  
        <h1>500</h1>
        <h5>{text("500T")}</h5>
        <Link to={"/"} >{ text('ErrorPageGoTo')}</Link>
   
    </div>
  );
}
