import React, { useContext } from "react";
import {faEdit,faRemove} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataContext from "../../../Contexts/DataContext";
export default function Shift({shift, changeEvent, deleteEvent}) {
  const { text } = useContext(DataContext);
  return (
    <tr>
      <td data-label={text("Shift_Name")}>{shift.name}</td>
      <td data-label={text("Start")}>{shift.startTime}</td>
      <td data-label={text("Stop")}>{shift.endTime}</td>
      <td className="text-right flex">
        <a
          href=""
          className="btn btn-sm btn-warning mr-10"
          data-toggle="modal"
          onClick={() => {
            changeEvent(shift);
          }}
          data-target="#changeModal"
        >
          <FontAwesomeIcon icon={faEdit} />
        </a>
        <a
          href=""
          className="btn btn-sm btn-danger mr-10"
          data-toggle="modal"
          onClick={() => {
            deleteEvent(shift.id);
          }}
          data-target="#deleteModal"
        >
          <FontAwesomeIcon icon={faRemove} />
        </a>
      </td>
    </tr>
  );
}
