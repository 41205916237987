import React from "react";
import ReactApexChart from "react-apexcharts";
function PercentileChart(text,value,name,height,color) {
  const options = {
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
        },
      },
    },
    colors: [color],
    labels: [text],
  };
  return (
    <div id={name}>
      <ReactApexChart
        options={options}
        series={[value.toFixed(0)]}
        type="radialBar"
        height={height}
      />
    </div>
  );
}

export default PercentileChart;
