import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { DataContextProvider } from "./Contexts/DataContext";
import "./i18n";
import Login from "./Pages/Login/Login";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <DataContextProvider>
      <Login />
  </DataContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
