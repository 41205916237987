import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft, faHome } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, } from 'react-router-dom';

export default function MobileNavigation() {
    const navigate = useNavigate();


    return (
        <div className='mobile-navigation'>
            <ul>
                <li><button><FontAwesomeIcon icon={faArrowLeft} onClick={()=>{navigate(-1)}} /></button></li>
                <li><button><FontAwesomeIcon icon={faHome} /></button></li>
                <li><button><FontAwesomeIcon icon={faArrowRight} onClick={()=>{navigate(1)}} /></button></li>
            </ul>
        </div>
    )
}
