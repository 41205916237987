import React, { useContext } from "react";
import DataContext from "../../Contexts/DataContext";
import RawMaterialCost from "./Rows/RawMaterialCost";

export default function RawMaterialCostTable({ cost,filterCosts }) {
    const { text } = useContext(DataContext);
    const filteredCosts = cost.rawMaterialCosts.filter((rawCost)=>{
        return rawCost.name.toLowerCase().includes(filterCosts.toLowerCase());
    })
    return (
        <div className="card">
            <div className="card-title">{cost.machineName}</div>
            <div className="cost-list">
                <table className="responsive-table">
                    <thead>
                        <tr>
                            <th>{text("Raw_Material_Name")}</th>
                            <th className="w-100-px">{text("Cost")}</th>
                            <th className="w-100-px">{text("Currency")}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredCosts.map((rawCost) => {
                                return (<RawMaterialCost key={rawCost.name} cost={rawCost} machineId={cost.machineId} />)
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
