import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrochip, faDisplay, faChartLine, faFileText, faDollar, faIdBadge, faFileInvoiceDollar, faUserGroup , faCalculator } from "@fortawesome/free-solid-svg-icons";
import { Link, NavLink } from "react-router-dom";
import DataContext from "../../Contexts/DataContext";

export default function Sidebar() {
  const { text } = useContext(DataContext);

  function dropdownClick(e) {
    e.currentTarget.parentNode.querySelector('ul').classList.toggle('show');
  }

  return (
    <div id="sidebar" className="d-flex flex-column flex-shrink-0  sidebar">
      <Link to="/" className="logo" >
        <img src="/logo.png" alt="" />
      </Link>
      <ul className="nav nav-pills flex-column mb-auto ">
        <li>
          <span className="menuTitle"> {text("Production")}</span>
          <ul>
            <li className="nav-item">
              <NavLink to="/" className="nav-link" aria-current="page">
                <FontAwesomeIcon icon={faMicrochip} />
                <span>{text("My_Machines")} </span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/screens" className="nav-link ">
                <FontAwesomeIcon icon={faDisplay} />
                <span> {text("My_Screens")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/livedata" className="nav-link">
                <FontAwesomeIcon icon={faChartLine} />
                <span>{text("Live_Datas")}</span>
              </NavLink>
            </li>

            <li className="dropdown" >

              <Link className="nav-link" onClick={dropdownClick}>
                <FontAwesomeIcon icon={faFileText} />
                <span> {text("Reports")}</span>
              </Link>

              <ul className="dropdown-menu">

                <li>
                  <NavLink to="/userReports" className="nav-link">
                    <FontAwesomeIcon icon={faIdBadge} />
                    <span>{text("User_Reports")}</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/productReports" className="nav-link">
                    <FontAwesomeIcon icon={faFileInvoiceDollar} />
                    <span>{text("Production_Reports")}</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/shiftReports" className="nav-link">
                    <FontAwesomeIcon icon={faUserGroup} />
                    <span>{text("Shift_Reports")} <span className="coming-soon">({text("Coming_Soon")})</span> </span>
                  </NavLink>
                </li>

              </ul>

            </li>

            <li>
              <NavLink to="/costs" className="nav-link">
                <FontAwesomeIcon icon={faDollar} />
                <span>{text("Raw_Material_Cost")}</span>
              </NavLink>
            </li>

          </ul>
        </li>


        <li>
          <span className="menuTitle"> {text("Crm")}</span>
          <ul>
            <li>
              <a href="#" aria-disabled="true" className="nav-link link-dark">
                <FontAwesomeIcon icon={faCalculator} />
                <span>ParsCRM</span>
              </a>
            </li>
            <li>

            </li>
          </ul>
        </li>
        <li>

        </li>
      </ul>

    </div>
  );
}

