import { createContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from 'react-cookie';
const DataContext = createContext();

export const DataContextProvider = ({ children }) => {
  const [cookies, setCookies, removeCookie] = useCookies(['session']);
  const { t: text, i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  const dateFormat = (dateTime) => {
    const date = new Date(dateTime);
    return date.toLocaleDateString() + " " + date.toLocaleTimeString();
  };
  const [settings, setSettings] = useState({
    theme: "light",
    sidebarToggle: true,
    loginToggle:false,
    loginIcon:false,
    reportType: 0,
  });

  const [cost, setCost] = useState({});

  const data = {
    changeLanguage,
    text,
    settings,
    setSettings,
    cookies,
    setCookies,
    removeCookie,
    dateFormat,
    cost,
    setCost
  };
  return <DataContext.Provider value={data}> {children} </DataContext.Provider>;
};
export default DataContext;
