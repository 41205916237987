import React, {useContext} from 'react'
import { Link } from 'react-router-dom'
import DataContext from '../../../Contexts/DataContext'
export default function E401() {
  const {text}= useContext(DataContext)
  return (
    <div className='response-error'>
        <h1>401</h1>
        <h5>{text ( "401Page")}</h5>
        <Link to={"/"} >{ text('ErrorPageGoTo')}</Link>
      
    </div>

  )
}
