import React from "react";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            2020 - <script>document.write(new Date().getFullYear())</script>2023
            © ParsConnect by{" "}
            <a target="_blank" href="https://parscontrol.com.tr/">
              ParsControl
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
