import { createContext, useState, useEffect } from "react";
import { ParsApi } from "../Components/Api/ParsApi";

const ApiContext = createContext();

export const ApiContextProvider = ({ children }) => {
  const { parsApi } = ParsApi();
  const [user, setUser] = useState(null);
  const [custumer, setCustomer] = useState(null);
  const [machines, setMachines] = useState(null);
  const [currencies, setCurrencies] = useState(null);

  useEffect(() => {
    function fillContext() {
      parsApi.getUser().then((x) => {
        if (x !== null) {
          setUser(x.data);
        } else {
          setUser(false);
        }
      });

      parsApi.getCustumer().then((x) => {
        if (x !== null) {
          setCustomer(x.data);
        }
      });

      parsApi.getMachines(setMachines);

      parsApi.getCurrenies().then((x) => {
        if (x !== null) {
          setCurrencies(x.data);
        }
      });
    }
    fillContext();
    /*
    const interval = setInterval(() => {
      fillContext();
    }, 10000);
    return () => clearInterval(interval);
    */
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const data = {
    user,
    custumer,
    machines,
    currencies,
  };

  return <ApiContext.Provider value={data}> {children} </ApiContext.Provider>;
};
export default ApiContext;
