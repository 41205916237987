import React, { useContext } from 'react'
import { faEye } from "@fortawesome/free-solid-svg-icons";
import DataContext from '../../../Contexts/DataContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';

export default function Order({ order }) {
    const { text,dateFormat } = useContext(DataContext);
    return (
        <tr>
            <td data-label={text("Order_No")} className="text-bold">{order.no}</td>
            <td data-label={text("Statu")} className={order.status ===0 ?"text-warning text-bold":"text-bold"}>{order.status===1 ? text("Done"):text("Continues")}</td>
            <td data-label={text("Start-Time")}>{dateFormat(order.data.startTime)}</td>
            <td className="text-right">
                <Link to={`/productReport/${order.data.machineInfo.id}?orderId=${order.no}`} className="btn btn-sm btn-success mr-10">
                    <FontAwesomeIcon icon={faEye} />
                </Link>
            </td>
        </tr>
    )
}
