import React, { useContext, useState, useEffect } from "react";
import RawMaterialCostTable from "../Components/Tables/RawMaterialCostTable";
import DataContext from "../Contexts/DataContext";
import { ParsApi } from "../Components/Api/ParsApi";
import PreLoader from "../Components/SeedWorks/PreLoader";
import Modal from "../Components/SeedWorks/Modal"
import E401 from "../Components/SeedWorks/Errors/E401";
import E404 from "../Components/SeedWorks/Errors/E404";
import E500 from "../Components/SeedWorks/Errors/E500";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
export default function Costs({ title }) {
  const {text,cost}= useContext(DataContext)
  const [costs, setCosts] = useState(null);
  const[filterCosts,setFilterCosts] = useState("")
  const { parsApi } = ParsApi();

  const updateCost = (e) => {
    e.preventDefault();
    parsApi.setRawMaterialCost(cost,true);
    window.$("#rawSaved").modal("hide");
  }

  useEffect(() => {
    parsApi.getRawMaterialCosts(setCosts);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (costs === null) {
    return (<PreLoader />)
  }
  else
    if (costs.response.code !== 200)
    {
      switch (costs.response.code)
      {
        case 401:
          return (<E401 />)
        case 404:
          return (<E404 />)
        case 501:
          return (<E500 />)
        default:
          return (<E500 />)
      }
    }
    else{
      const handleFilterChange = (event)=>{
      setFilterCosts(event.target.value)
      }
      return (
        <div className="costs">
          <div className="row content-header">
            <div className="col-12">
              <div className="page-title flex flex-between">
                <h5>{title}</h5>
                <div className="flex search-bar " > 
                  <input placeholder={text("Search")} className="cost-input" onChange={handleFilterChange} />
                  <FontAwesomeIcon className="search"  icon={faMagnifyingGlass} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {
              costs.response.data.map((cost) => {
                return (
                  <div className="col-md-6 col-lg-4" key={cost.machineId}>
                    <RawMaterialCostTable cost={cost} filterCosts={filterCosts} />
                  </div>
                )
              })
            }

          </div>
          <Modal
            content={() =>{
              return <div>
                <span className="text-center">{text("Raw_Material_Cost_Information_Recorded")}</span>
                <br/>
                <a className="float-right btn btn-sm btn-success mt-3" href="">{text("Confirm")}</a>
                <a className="float-right btn btn-sm btn-info mr-10 mt-3" href="" onClick={updateCost}>{text("Apply_to_All_Machines")}</a>
              </div>
            } }
            id={"rawSaved"}
            title={text("Knowledge")}
          />
          <Modal
            content={() => text("Raw_Material_Cost_Information_Could_Not_Be_Saved")}
            id={"rawNotSaved"}
            title={text("Mistake")}
          />
          <Modal
            content={() => text("Registered_For_All_Machines")}
            id={"rawSavedAll"}
            title={text("Changes_Saved")}
            footerVisible={true}
            event={()=>{window.location.reload()}}
          />
        </div>
      );

    }

   
}
