import React, { useContext } from "react";
import DataContext from "../../Contexts/DataContext";
import PercentileChart from "../Charts/PercentileChart";
import Preloader from "../SeedWorks/PreLoader"
export default function FactoryCapacityCard({ machines }) {
  const { text } = useContext(DataContext);

  const totalMachinePiece = machines.length;
  const activeMachinePiece = machines.filter(machine => machine.isConnected).length;
  const passiveMachinePiece = totalMachinePiece - activeMachinePiece;
  const factoryCapacities = machines.map(machine => machine.machineData.activeCapacity).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const percentCapacity = activeMachinePiece === 0 ? 0 : (activeMachinePiece / totalMachinePiece * 100);

  if (machines == null) {
    return <Preloader />
  }
  else
    return (
      <div className="card">
        <div className="card-title">{text("Machine_State")}</div>
        <div className="chart">
          {PercentileChart(
            `${text("Production_Capacity")}`,
            percentCapacity,
            "allCapacityChart",
            350,
            "#ffc913"
          )}
        </div>

        <div className="card-center mb-4">
          <h5 className="success">{text("All_Machines_Actual_Capacity")}</h5>
          <h3>{factoryCapacities.toFixed(2)} {text("kg/h")}</h3>
        </div>

        <div className="chart-item">
          <div className="row">
            <div className="col-md-4">
              <div className="chartItemTitle info">{text("Total_Machines")}</div>
              <div className="chartItemValue">{machines.length}</div>
            </div>

            <div className="col-md-4 border-lr">
              <div className="chartItemTitle success">{text("Active")}</div>
              <div className="chartItemValue">{activeMachinePiece}</div>
            </div>

            <div className="col-md-4">
              <div className="chartItemTitle danger">{text("Passive")}</div>
              <div className="chartItemValue">{passiveMachinePiece}</div>
            </div>
          </div>
        </div>
      </div>
    );
}
