import React, { useContext, useState, useEffect } from 'react'
import RawConsuptionCard from '../Components/Cards/RawConsuptionCard'
import ReportHeadCard from '../Components/Cards/ReportHeadCard'
import RecipeTable from '../Components/Tables/RecipeTable'
import MachineReportLineGraphCard from '../Components/Cards/MachineReportLineGraphCard'
import DataContext from '../Contexts/DataContext'
import PreLoader from "../Components/SeedWorks/PreLoader";
import { useParams } from 'react-router-dom';
import { ParsApi } from "../Components/Api/ParsApi";
import PrintButton from '../Components/SeedWorks/PrintButton'
import E401 from "../Components/SeedWorks/Errors/E401";
import E404 from "../Components/SeedWorks/Errors/E404";
import E500 from "../Components/SeedWorks/Errors/E500";


export default function MachineReport() {
    const { text,dateFormat } = useContext(DataContext);
    const { id } = useParams();
    const { parsApi } = ParsApi();
    const [report, setReport] = useState(null);
    const [reportData, setReportData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            parsApi.getReport(id,setReport,setReportData)
        };
        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (report === null)
    {
        return (<PreLoader />)
    }
    else if (report.response.code !== 200) {
        switch (report.response.code) {
          case 401:
            return <E401 />;
          case 404:
            return <E404 />;
          case 501:
            return <E500 />;
          default:
            return <E500 />;
        }
      }else{
        const dataReport = report.response.data
        const dateReportData = JSON.parse(dataReport.data)
        
        return (

            <div className="machineReport">
                <div className="row content-header">
                    <div className="col-12">
                        <div className="page-title">
                            <h5>{text("Machine_Report")}</h5>
                        </div>
                    </div>
                </div>
                <div className="row print-card ">
                    <div className="col-sm-3 col-md-6 col-lg-3">
                        <ReportHeadCard title={text("Report_Name")} value={dataReport.name} />
                    </div>
                    <div className="col-sm-3 col-md-6 col-lg-3">
                        <ReportHeadCard title={text("Machine_Name")} value={dataReport.machine.name} />
                    </div>
                    <div className="col-sm-3 col-md-6 col-lg-3">
                        <ReportHeadCard title={text("Start_Time")}  value={dateFormat(reportData.startTime)}/>
                    </div>
                    <div className="col-sm-3 col-md-6 col-lg-3">
                        <ReportHeadCard title={text("Stop_Time")}  value={dateFormat(reportData.endTime)}/>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-sm-7 ">
                        <RecipeTable rawMaterials={reportData.recipe} kgCosts={dateReportData.totalCosts}   />
                    </div>
                    <div className="col-sm-5"  >
                        <RawConsuptionCard rawMaterials={reportData.recipe}  />
                    </div>
                </div>
                <div className="row ">
                    <div className="col-sm-6 col-md-6 ">
                        <MachineReportLineGraphCard title={text("Capacity_Graph")} series={reportData?.activeCapacitySeries} />
                    </div>
                    <div className="col-sm-6 col-md-6 ">
                        <MachineReportLineGraphCard title={text("Weight_Chart")} series={reportData?.activeGrammageSeries} />
                    </div>
                    <div className="col-sm-6 col-md-6 ">
                        <MachineReportLineGraphCard title={text("Thickness_Chart")} series={reportData?.activeThicknessSeries} />
                    </div>
                    <div className="col-sm-6 col-md-6 ">
                        <MachineReportLineGraphCard title={text("Line_Speed_Chart")} series={reportData?.activeLineSpeedSeries} />
                    </div>
                </div>
                <PrintButton/>
            </div>
        )

      }
     
}
