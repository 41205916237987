import React, { useContext } from "react";
import DataContext from "../../../Contexts/DataContext";

export default function RecipeRaw({ rawMaterial,index }) {
  const { text } = useContext(DataContext);
  if (rawMaterial.ratio != 0) {
    return (
      <tr>
        <td data-label={text("Raw_Material_Name")}>{rawMaterial.name} {index}</td>
        <td className="ratio" data-label={text("Rate")}>{rawMaterial.ratio.toFixed(2)} %</td>
        <td data-label={text("Total_Consumption")}>{rawMaterial.totalConsumption.toFixed(2)} {text("kg")}</td>
        <td data-label={text("Cost")}>{rawMaterial.kgCost.toFixed(2)} {rawMaterial.currencyUnit}</td>
        <td data-label={text("Consumption_Cost")}>{rawMaterial.totalConsumptionCost.toFixed(2)} {rawMaterial.currencyUnit}</td>
      </tr>
    );
  }
}
