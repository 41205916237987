import React, { useContext } from 'react'
import ApiContext from '../Contexts/ApiContext'
import ProfileUpdateForm from '../Components/Forms/ProfileUpdateForm';
import PreLoader from '../Components/SeedWorks/PreLoader';
export default function UpdateProfile({title}) {
    const { user } = useContext(ApiContext);
    if(user==null)
    {
        <PreLoader/>
    }
    else
    return (
        <section className="vh-100 gradient-custom register">      
            <div className="container py-5 h-100">
                <div className="row justify-content-center align-items-center h-100">
                    <div className="col-12 col-lg-9 col-xl-7">
                        <div className="card shadow-2-strong card-registration" >
                            <div className="card-body p-4 p-md-5 ">
                                <h3 className="card-title d-flex justify-content-between">
                                    <span>
                                        {title}
                                    </span>
                                    <img src="/logo.png" width={200} alt="" />
                                </h3>
                                <ProfileUpdateForm user={user}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
